import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'

interface IProps {
  user: any
}

class AdminBar extends PureComponent<IProps> {
  render() {
    const { user } = this.props

    if (!user || (user.role !== 'admin' && user.role !== 'admin-editor')) {
      return null
    }

    return (
      <div className="admin-bar button-full button--orange no-hover padding-horizontal">
        <p className="paragraph text-right">
          <span className="float-left">
            <a href="/admin/job/list">Admin bar</a>
          </span>

          <a href="/admin/job/list">joby</a>
          <Link href="/admin/freelancers/list">Freelanceri</Link>
          <Link href="/admin/user-db/list">DB</Link>
          <span> |</span>
          <a href="/admin/category/list">kategórie</a>
          <a href="/admin/city/list">mestá</a>
          <a href="/admin/country/list">krajiny</a>
          <a href="/admin/tags/list">tagy</a>
          <a href="/admin/company/list">firmy</a>
          <a href="/admin/promo-code/list">promo kódy</a>
          <span> |</span>
          <Link href="/admin/photo-upload">logoUpload</Link>
          <a href="/admin/payment/list">platby</a>
          <a href="/admin/emails">emaily</a>
          <a href="/admin/stats">stats</a>
          <span> | {user?.role}</span>
          <a href="/admin/logout">Logout</a>
        </p>
      </div>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  user,
})

export default connect(mapStateToProps)(AdminBar)
