module.exports = {
  title: '#NárodnáGalériaPráce',
  tag: {
    sng: '#NárodnáGalériaPráce',
    support: '#HľadámePodporu',
  },
  job: {
    banner: 'Support the creation of this job at',
    sng: {
      heading: '#NárodnáGalériaPráce',
      text: 'I want to join the initiative and help professionals<br/> from the world of culture who are currently looking for work',
      textFreelancer:
        'I am a professional from the field of culture and I am currently looking for a job',
    },
    sngSupport: {
      heading: '#HľadámePodporu',
      text: 'I am a cultural or non-profit institution that wants to create a new job,<br/> but does not yet have the necessary funds to finance it',
    },
  },
}
