import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown'
import Link from 'next/link'
import { withRouter, NextRouter } from 'next/router'
import TrackingUtils from 'utils/tracking'
import Translate from 'translations/Translate'
import Emoji from 'components/Emoji'
import { IProfile, FreelancerType } from 'redux/interfaces/profile'
import FreelanerFavouriteHeaderButton from 'components/freelancers/FreelancerFavouriteHeaderButton'
import HeaderLogo from './header/HeaderLogo'
import LanguageDropdownSwitch from './LanguageDropdownSwitch'

interface IProps {
  appType?: string
  isCompanyLogged: boolean
  isUserLogged: boolean
  context: string
  profile: IProfile
  router: NextRouter
}

interface IState {
  menuVisible: boolean
}

class Header extends PureComponent<IProps, IState> {
  dropdownRef = React.createRef()
  timer = null

  state: IState = {
    menuVisible: false,
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.context !== this.props.context) {
      if (document) {
        document.body.classList.remove('modal-visible')
      }

      this.setState({ menuVisible: false })
    }
  }

  handleLogout = () => {
    TrackingUtils.event('logout', { event_action: 'logout' })
    // Note: We want refresh all app data here
    window.location.href = '/logout'
  }

  toggleMenu = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0)
    const oldMenuState = this.state.menuVisible

    if (oldMenuState) {
      document.documentElement.classList.remove('modal-visible')
    } else {
      document.documentElement.classList.add('modal-visible')
    }

    this.setState({ menuVisible: !oldMenuState })
  }

  closeMenu = () => {
    if (document) {
      document.documentElement.classList.remove('modal-visible')
    }

    this.setState({ menuVisible: false })
  }

  hideDropdown = () => {
    // @ts-ignore
    this.dropdownRef.current.hide()
    this.closeMenu()
  }

  getMainMenuItems = () => {
    const { appType, router } = this.props
    const pathname = router?.pathname

    return (
      <Translate>
        {(t) => (
          <>
            <Link
              href="/"
              onClick={this.closeMenu}
              className={classnames('header__menu-item', {
                'header__menu-item--active': pathname === '/',
              })}
            >
              {t('common.jobOffers')}
            </Link>
            <Link
              href="/freelancers"
              onClick={this.closeMenu}
              className={classnames('header__menu-item', {
                'header__menu-item--active': pathname === '/freelancers',
              })}
            >
              {t('common.freelancers')}
            </Link>

            <span className="header__divider" />

            {appType === 'standard' && (
              <>
                <Link
                  href="/firmy"
                  onClick={this.closeMenu}
                  className={classnames('header__menu-item', {
                    'header__menu-item--active': pathname === '/firmy',
                  })}
                >
                  {t('common.companies')}
                </Link>
                <Link
                  href="/cennik"
                  onClick={this.closeMenu}
                  className={classnames(
                    'header__menu-item header__menu-item--responsive-hide-2',
                    {
                      'header__menu-item--active': pathname === '/cennik',
                    }
                  )}
                >
                  {t('common.pricing')}
                </Link>
                <Link
                  href="/narodnagaleriaprace"
                  onClick={this.closeMenu}
                  className={classnames(
                    'header__menu-item header__menu-item--responsive-hide-1',
                    {
                      'header__menu-item--active': pathname === '/sng',
                    }
                  )}
                >
                  {t('sng.title')}
                </Link>
              </>
            )}
            {appType === 'freelancers' && (
              <>
                <Link
                  href="/freelancers/pro"
                  onClick={this.closeMenu}
                  className={classnames(
                    'header__menu-item header__menu-item--nounderline header__menu-item--responsive-hide-2',
                    {
                      'header__menu-item--active': pathname === '/freelancers/pro',
                    }
                  )}
                >
                  <span className="pill pill--super-small pill--font-bold header__menu-item-pill">
                    <Emoji>{t('freelancers.pro.label')}</Emoji>
                  </span>
                  <span className="text">{t('freelancers.membership')}</span>
                </Link>
              </>
            )}
          </>
        )}
      </Translate>
    )
  }

  getCompanyControls = (view: 'mobile' | 'desktop') => {
    return (
      <Translate>
        {(t) => (
          <>
            <div
              className={classnames({
                'dropdown__inner dropdown__inner-single': view === 'desktop',
              })}
            >
              <Link
                href="/profile/home"
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.hideDropdown}
              >
                {t('navigation.controls.viewProfile')}
              </Link>
              <Link
                href="/profile/company"
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.hideDropdown}
              >
                {t('common.companyInfo')}
              </Link>
              <Link
                href="/profile/settings"
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.hideDropdown}
              >
                {t('navigation.controls.accountSettings')}
              </Link>
              <Link
                href="/profile/invoices"
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.hideDropdown}
              >
                {t('common.invoices')}
              </Link>
            </div>
            <div
              className={classnames({
                'dropdown__inner dropdown__inner-single': view === 'desktop',
              })}
            >
              <a
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.handleLogout}
              >
                {t('common.actions.logout')}
              </a>
            </div>
          </>
        )}
      </Translate>
    )
  }

  getNotLoggedControls = (view: 'mobile' | 'desktop') => {
    return (
      <Translate>
        {(t) => (
          <>
            <div
              className={classnames({
                'dropdown__inner dropdown__inner-single': view === 'desktop',
              })}
            >
              <Link
                href="/login"
                className="dropdown__item job__info job__info-link job__info-link--profile job__info-link--with-icon"
                onClick={this.hideDropdown}
              >
                <Emoji className="header__company-emoji">🏬</Emoji>{' '}
                <span>{t('navigation.controls.forCompanies')}</span>
              </Link>
            </div>
            <div
              className={classnames({
                'dropdown__inner dropdown__inner-single': view === 'desktop',
              })}
            >
              <Link
                href="/freelancers/login"
                className="dropdown__item job__info job__info-link job__info-link--profile job__info-link--with-icon"
                onClick={this.hideDropdown}
              >
                <Emoji className="header__company-emoji">🦄</Emoji>{' '}
                <span>{t('navigation.controls.forFreelancer')}</span>
              </Link>
            </div>
          </>
        )}
      </Translate>
    )
  }

  getFreelancerControls = (view: 'mobile' | 'desktop') => {
    const { profile } = this.props

    return (
      <Translate>
        {(t) => (
          <>
            <div
              className={classnames({
                'dropdown__inner dropdown__inner-single': view === 'desktop',
              })}
            >
              {profile.freelancerType === FreelancerType.STANDARD && (
                <Link
                  href="/freelancers/pro"
                  className="upsell-line"
                  onClick={this.hideDropdown}
                >
                  <span className="pill pill--super-small">
                    <Emoji>{t('freelancers.pro.label')}</Emoji>
                  </span>
                  <span className="paragraph paragraph--super-small paragraph--bold">
                    {t('freelancers.upsell.menu')}
                  </span>
                </Link>
              )}
              <Link
                href="/freelancers/profile/home"
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.hideDropdown}
              >
                {t('navigation.controls.viewProfile')}
              </Link>
              <Link
                href="/freelancers/profile/edit"
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.hideDropdown}
              >
                {t('common.editProfile')}
              </Link>
              <Link
                href="/freelancers/profile/settings"
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.hideDropdown}
              >
                {t('navigation.controls.accountSettings')}
              </Link>
            </div>
            <div
              className={classnames({
                'dropdown__inner dropdown__inner-single': view === 'desktop',
              })}
            >
              <a
                className="dropdown__item job__info job__info-link job__info-link--profile"
                onClick={this.handleLogout}
              >
                {t('common.actions.logout')}
              </a>
            </div>
          </>
        )}
      </Translate>
    )
  }

  getControls = (controlsType: string) => {
    const { appType, isCompanyLogged, isUserLogged } = this.props

    if (appType === 'admin') {
      return
    }

    return (
      <Translate>
        {(t) => (
          <>
            {appType === 'freelancers' && !isUserLogged && !isCompanyLogged && (
              <Link
                href="/freelancers/signup"
                onClick={this.closeMenu}
                className="header__button"
              >
                <span className="button__in black">
                  <span>{t('common.addProfile')}</span>
                </span>
              </Link>
            )}

            {appType === 'freelancers' &&
              isUserLogged &&
              !isCompanyLogged &&
              this.props.profile?.freelancerType === FreelancerType.STANDARD && (
                <Link
                  href="/freelancers/pro"
                  onClick={this.closeMenu}
                  className="header__button"
                >
                  <span className="button__in black">
                    <span>
                      <Emoji>🔥</Emoji> {t('freelancers.title')}
                    </span>
                  </span>
                </Link>
              )}

            {appType !== 'freelancers' && (
              <Link
                href={isCompanyLogged ? '/job/add' : '/cennik'}
                onClick={this.closeMenu}
                className="header__button"
                data-cy={`add-job--${controlsType}`}
              >
                <span className="button__in">
                  <span>{t('common.addJob')}</span>
                </span>
              </Link>
            )}

            <div className="header__company">
              {controlsType === 'mobile' && <LanguageDropdownSwitch />}
              {isCompanyLogged && controlsType === 'desktop' && (
                <Dropdown ref={this.dropdownRef}>
                  <DropdownTrigger>
                    <div
                      className={classnames({
                        'dropdown__handler-black': appType === 'freelancers',
                        'dropdown__handler-white': appType !== 'freelancers',
                      })}
                    >
                      <Emoji className="header__company-emoji">🏬</Emoji>
                      <span className="header__company-link">
                        <span className="header__company-link--desktop">
                          {t('navigation.controls.myCompany')}
                        </span>
                        <span className="header__company-link--mobile">
                          {t('common.company')}
                        </span>
                      </span>
                    </div>
                  </DropdownTrigger>
                  <DropdownContent>{this.getCompanyControls('desktop')}</DropdownContent>
                </Dropdown>
              )}
              {isCompanyLogged && controlsType === 'mobile' && (
                <div className="header__mobile-menu-links">
                  {this.getCompanyControls('mobile')}
                </div>
              )}
              {isUserLogged && controlsType === 'desktop' && (
                <Dropdown ref={this.dropdownRef}>
                  <DropdownTrigger>
                    <div
                      className={classnames({
                        'dropdown__handler-black': appType === 'freelancers',
                        'dropdown__handler-white': appType !== 'freelancers',
                      })}
                    >
                      <Emoji className="header__company-emoji">🦄</Emoji>
                      <span className="header__company-link">
                        <span className="header__company-link--desktop">
                          {t('common.myProfile')}
                        </span>
                        <span className="header__company-link--mobile">
                          {t('common.profile')}
                        </span>
                      </span>
                    </div>
                  </DropdownTrigger>
                  <DropdownContent>
                    {this.getFreelancerControls('desktop')}
                  </DropdownContent>
                </Dropdown>
              )}
              {isUserLogged && controlsType === 'mobile' && (
                <div className="header__mobile-menu-links">
                  {this.getFreelancerControls('mobile')}
                </div>
              )}
              {!isCompanyLogged && !isUserLogged && controlsType === 'desktop' && (
                <Dropdown ref={this.dropdownRef}>
                  <DropdownTrigger>
                    <div
                      className={classnames({
                        'dropdown__handler-black': appType === 'freelancers',
                        'dropdown__handler-white': appType !== 'freelancers',
                      })}
                    >
                      <Emoji className="header__company-emoji">👋</Emoji>
                      <span className="header__company-link">
                        {t('common.loginTitle')}
                      </span>
                    </div>
                  </DropdownTrigger>
                  <DropdownContent>
                    {this.getNotLoggedControls('desktop')}
                  </DropdownContent>
                </Dropdown>
              )}
              {!isCompanyLogged && !isUserLogged && controlsType === 'mobile' && (
                <div className="header__mobile-menu-links">
                  {this.getNotLoggedControls('mobile')}
                </div>
              )}
              {controlsType === 'desktop' && (
                <LanguageDropdownSwitch
                  theme={appType === 'freelancers' ? 'dark' : 'light'}
                />
              )}
              {(isCompanyLogged || isUserLogged) && appType === 'freelancers' && (
                <FreelanerFavouriteHeaderButton />
              )}
            </div>
          </>
        )}
      </Translate>
    )
  }

  render() {
    const { appType } = this.props
    const { menuVisible } = this.state

    return (
      <Translate>
        {(t) => (
          <header
            className={classnames('header', {
              'header--green': appType === 'freelancers',
              'header--orange': appType === 'admin',
            })}
          >
            <HeaderLogo
              onClick={this.closeMenu}
              appType={appType}
              variant={
                appType === 'freelancers' || appType === 'admin' ? 'black' : 'default'
              }
            />

            {appType === 'admin' && <p className="header__text">Administrácia</p>}

            {appType !== 'admin' && (
              <p className="header__text">{this.getMainMenuItems()}</p>
            )}

            <a
              className="header__menu-button header__menu-button--open"
              onClick={this.toggleMenu}
            />
            <div className="header__right-desktop">{this.getControls('desktop')}</div>

            <div
              className={classnames('header__mobile-menu', {
                'header__mobile-menu--visible': menuVisible,
              })}
            >
              <div className="header__mobile-menu-heading">
                <HeaderLogo isStatic appType={appType} onClick={this.closeMenu} />
                <a
                  className="header__menu-button header__menu-button--close"
                  onClick={this.toggleMenu}
                />
              </div>
              <div>
                {this.getMainMenuItems()}
                <Link
                  href="/statistiky"
                  onClick={this.closeMenu}
                  className="header__menu-item"
                >
                  {t('common.statistics')}
                </Link>
              </div>
              <div className="header__mobile-controls">{this.getControls('mobile')}</div>
            </div>
          </header>
        )}
      </Translate>
    )
  }
}

const mapStateToProps = ({ profile }) => ({
  profile,
})

export default connect(mapStateToProps)(withRouter(Header))
