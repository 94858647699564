module.exports = {
  keywords:
    'creative jobs, freelance jobs, design job, job offers, work for a designer, work for a developer, work for a marketer',
  title: {
    admin: {
      freelancers: 'Admin: Freelancers',
      social: 'Admin: Job - Post to social networks',
      photoUpload: 'Admin: Photo upload',
      contact: 'Admin: Contact',
      db: 'Admin: Users DB',
      company: {
        jobs: 'Admin: Company - Jobs',
      },
    },
    default: 'Joby in the field of design, IT, marketing and Remote',
    home: 'Joby for Designers, Marketers, IT and Remote',
    payment: 'Payment details',
    pricing: 'Price list',
    emailAdded: 'Cool, you have been added to our newsletter',
    emailAlmostAdded: 'We already have 99% ...',
    companies: 'Companies',
    forgotPassword: 'Forgotten company profile password',
    forgotPasswordSuccess: 'Password successfully reset',
    freelancersDeleteSuccess: 'Delete profile successful',
    freelancersDetail: 'Freelancers - designers, marketers, developers, creatives',
    freelancersPasswordSuccess: 'Freelancer password reset successfully',
    freelancersForgotPassword: 'Forgotten password freelancer',
    freelancers: 'Freelancers - designers, marketers, developers, creators',
    freelancersLogin: 'Login to freelancer profile',
    freelancersBonus: 'Bonuses',
    freelancersEdit: 'Edit profile',
    freelancersMyProfile: 'My Profile',
    freelancersProfileSettings: 'Account settings',
    freelancersResetPasswordSuccess: 'New freelancer profile password is in the world',
    freelancersPro: 'Switch to PRO and get more!',
    freelancersFavourite: 'Favourite',
    freelancersResetPassword: 'Enter your new password for the freelancer profile',
    freelancersSignup: 'Freelancer registration',
    gdpr: 'Privacy',
    addJob: 'Add Job',
    editJob: 'Edit Job',
    editJobDraft: 'Edit Job draft',
    republishJob: 'Job Duplication',
    jobEditNotAllowed: 'There is a problem',
    jobEditSuccess: 'Job edit successful',
    jobEditError: 'Job edit failed',
    jobCvError: 'Request not sent',
    jobCvSuccess: 'Request Sent',
    login: 'Login to company profile',
    magazineDetail: 'Overpressure Magazine',
    magazine: 'Overpressure Magazine',
    unsubscribe: 'Email notification settings',
    approvalUser: 'Consent of the data subject to the processing of personal data',
    approvalUserDb: 'Consent of the data subject to the processing of personal data',
    approvalMarketing: 'Consent of the data subject to the processing of personal data',
    stats: 'Current monthly statistics',
    signup: 'Register your company',
    resetPassword: 'Enter your new company profile password',
    resetPasswordSuccess: 'New password is in the world',
    companySettings: 'Account Settings',
    jobReport: 'Job Report',
    companyInvoices: 'Your invoices',
    companyProfileHome: 'Jobs',
    companyFreelancers: 'Freelancers',
    companyProfileDb: 'CV Database',
    companyProfileEdit: 'Company Data',
    companyProfileBuy: 'Bargain packages',
    termsOfUse: 'Terms of use',
    about: 'A few words about us',
    magazineCategory: 'Overpressure Magazine',
    manifest: '#NOJUDGEMENT',
    sng: '#NárodnáGalériaPráce',
  },
  description: {
    default:
      'Current jobs for designers, developers, marketers and creatives. Aj Remote. Find your dream job and a new colleague. ',
    home: 'We are a modern job portal with an overview of current jobs for designers, marketers, developers and creatives. Aj Remote. ',
    payment: 'Please check your billing information and select a payment method',
    pricing: 'See the current price list of our services',
    emailAdded:
      "Every week you will receive an e-mail with current offers in your inbox. Cool, isn't it? ",
    emailAlmostAdded:
      'Now check your inbox. Just click on the link and you will be in the game! ',
    companies: 'See companies that advertise with us',
    forgotPassword:
      'Forgot your company profile password? Never mind, enter your registration e-mail and we will reset it nicely. ',
    forgotPasswordSucces: 'Now check your email and complete the password reset process.',
    freelancersDeleteSuccess:
      "We're sorry you're leaving, but that's life. If anything, you know where to find us. ",
    freelancersDetail: 'Choose your freelancer from your current list and get to work.',
    freelancersPasswordSuccess:
      'You can now check your email and complete the password reset process.',
    freelancersForgotPassword:
      'Forgotten password? Never mind, enter your e-mail and we will reset it nicely. ',
    freelancers: 'Choose your freelancer from your current list and get to work.',
    freelancersLogin:
      'Log in to your profile and get it to the maximum. Clients are already waiting. ',
    freelancersResetPasswordSuccess:
      'Fantasy. Enjoy your new password and also our website. See you.',
    freelancersResetPassword:
      "Just a step and that's it. Please enter your new password. The ones you remember. Or not. ",
    freelancersSignup:
      'Create your profile and be part of the top freelancer in Slovakia',
    freelancersPro:
      'With a PRO account, your profile will take on a whole new dimension. Benefit from the benefits that will put you in the forefront. Get more work, but also pay. Are you going to do it?',
    gdpr: "Do we care about privacy? Of course. Here you will find everything about the GDPR and your person. Otherwise, it's quite boring.",
    jobEditNotAllowed: 'You need a working password to edit this offer.',
    jobEditSuccess: 'Great! The job was successfully edited. ',
    jobEditError: 'Hey, something went wrong. Please try again. ',
    jobCvError: 'Oops, there was an error somewhere, try again.',
    jobCvSuccess: 'Excellent! The request was sent successfully. ',
    login:
      'Log in to the company profile, add a job, see candidates, buy a bargain package or top up your offer',
    magazine:
      'Quality interviews with creators, news from the industry or inspiration for every day',
    unsubscribe: 'Set what email notifications you want to receive from us',
    approvalUser:
      'Here you will find the purpose of processing your personal data and other important information.',
    approvalUserDb:
      'Here you will find the purpose of processing your personal data and other important information.',
    approvalMarketing:
      'Here you will find the purpose of processing your personal data and other important information.',
    stats:
      "If you're interested in how Pretlak did last month, wait for our stats. You will only find real data here. We promise.",
    signup:
      'Add your business today and benefit from a number of benefits - quick job management, a list of candidates, secret offers',
    resetPassword: 'Now enter your new password and write it down. Or not. ',
    resetPasswordSuccess:
      'Wow. You have successfully created a new password. We look forward to seeing you. ',
    termsOfUse:
      'Do we have any terms and conditions on our website? A certain, yes, and you will find them here. ',
    about:
      "Who are we, what are we doing, what are we here for? You'll find out here ...",
    magazineCategory:
      'Quality interviews with creators, news from the industry or inspiration for every day',
    manifest:
      'At Pretlak, we believe that diversity in a workplace provides a lot of benefits and nobody should be discriminated against. Employers supporting our manifesto therefore declare that they will create and maintain an inclusive workplace for their employees. That way the candidates can feel free to be themselves in their future jobs, regardless of their sexual orientation, gender, age, ethnicity, religion or medical disability.',
    sng: 'Kultúra nie je len o inštitúciách. Kultúra je o ľuďoch.',
  },
}
