import getConfig from 'next/config'
import striptags from 'striptags'
import { parseCookies } from 'nookies'
import { Remarkable } from 'remarkable'
import { linkify } from 'remarkable/linkify'
import extLink from 'remarkable-extlink'
import slugify from 'slugify'
import RouterUtils from './router'

let md = new Remarkable({
  breaks: true,
})
  .use(linkify)
  .use(extLink, { host: 'pretlak.com' })

md.block.ruler.disable(['code'])

const { publicRuntimeConfig } = getConfig()
const { APP_URL, NODE_ENV, WEB_VERSION, GTM_TRACKING_ID } = publicRuntimeConfig

let HOST = APP_URL

export { NODE_ENV, WEB_VERSION, HOST, GTM_TRACKING_ID }

export const getUserId = (ctx?: any) => {
  return ctx ? parseCookies(ctx)['userId'] : parseCookies()['userId']
}

export const getCompanyLogged = (user: any) => {
  if (!user) {
    return false
  }
  return Boolean(user.role === 'standard')
}

export const getUserLogged = (user: any) => {
  if (!user) {
    return false
  }
  return Boolean(user.role === 'user')
}

export const getAdminLogged = (user: any) => {
  if (!user) {
    return false
  }
  return Boolean(user.role === 'admin' || user.role === 'admin-editor')
}

export const redirectIfCompanyLogged = async (user: any) => {
  if (getCompanyLogged(user)) {
    await RouterUtils.push('/profile/home')
  }
}

export const redirectIfUserLogged = async (user: any) => {
  if (getUserLogged(user)) {
    await RouterUtils.push('/freelancers/profile/home')
  }
}

export const normalizeHtmlContent = (content) => {
  const trimmed = content.trim()
  const stripped = striptags(trimmed)

  return md.render(stripped)
}

export function logEvents(event, color, content) {
  if (NODE_ENV !== 'production') {
    // eslint-disable-next-line
    console.log(
      `%c${event.toUpperCase()}`,
      `background:${color};color:#fff;padding:3px 7px;line-height:20px`,
      ...content
    )
  }
}

export function getSlug(str?: string) {
  if (!str) {
    return str
  }

  return slugify(str.trim(), {
    lower: true,
    strict: true,
  })
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}
