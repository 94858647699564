module.exports = {
  title: '#NárodnáGalériaPráce',
  tag: {
    sng: '#NárodnáGalériaPráce',
    support: '#HľadámePodporu',
  },
  job: {
    banner: 'Podpořte vznik tohoto pracovního místa na',
    sng: {
      heading: '#NárodnáGalériaPráce',
      text: 'Chci se zapojit do iniciativy a pomoci profesionálům<br/>ze světa kultury, kteří aktuálně hledají práci.',
      textFreelancer: 'Jsem profesionál*ka z oblasti kultury a aktuálně si hledám práci',
    },
    sngSupport: {
      heading: '#HľadámePodporu',
      text: 'Jsem kulturní nebo nezisková instituce, která chce vytvořit nové<br /> pracovní místo, ale zatím nemá k jeho financování potřebné prostředky.',
    },
  },
}
