import React from 'react'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import Translate from 'translations/Translate'
import { setCookiesVisibleContext } from 'redux/managers/general'
import Emoji from 'components/Emoji'

interface IProps {
  appType?: string
  isCompanyLogged: boolean
}

const Footer: React.FC<IProps> = ({ appType }) => {
  const dispatch = useDispatch()

  const showCookies = (e) => {
    e.preventDefault()

    dispatch(setCookiesVisibleContext(true))
  }

  if (appType === 'admin') {
    return null
  }

  return (
    <Translate>
      {(t) => (
        <>
          <div className="footer">
            <div className="container footer__container">
              <div className="footer__box">
                <p>
                  <Link href="/" className="footer__link">
                    {t('common.jobOffers')}
                  </Link>
                </p>
                <p>
                  <Link href="/freelancers" className="footer__link">
                    {t('common.freelancers')}
                  </Link>
                </p>
                <p>
                  <Link href="/firmy" className="footer__link">
                    {t('common.companies')}
                  </Link>
                </p>
                <p>
                  <Link href="/cennik" className="footer__link">
                    {t('common.pricing')}
                  </Link>
                </p>
              </div>

              <div className="footer__box">
                <p>
                  <Link href="/freelancers/pro" className="footer__link">
                    <Emoji>🔥 PRO</Emoji>
                  </Link>
                </p>
                <p>
                  <Link href="/manifest" className="footer__link">
                    {t('manifest.name')}
                  </Link>
                </p>
                <p>
                  <Link href="/mag/work-bike-balance" className="footer__link">
                    WORK BIKE BALANCE
                  </Link>
                </p>
                <p>
                  <Link
                    href="https://bratiska.sk/vysledky-vyhladavania?search=pretlak"
                    target="_blank"
                    className="footer__link footer__link--no-underline"
                  >
                    <span className="footer__link-text">Merch</span>{' '}
                    <span className="pill pill--super-small pill--orange">WOW</span>
                  </Link>
                </p>
              </div>

              <div className="footer__box">
                <div className="footer__logo" />
              </div>

              <div className="footer__box">
                <p>
                  <Link href="/o-nas#contact" className="footer__link">
                    {t('common.contact')}
                  </Link>
                </p>
                <p>
                  <Link href="/o-nas" className="footer__link">
                    {t('common.aboutUs')}
                  </Link>
                </p>
                <p>
                  <Link href="/mag" className="footer__link footer__link-no-margin">
                    {t('common.magazine')}
                  </Link>
                </p>
              </div>

              <div className="footer__box">
                <p>
                  <a
                    className="footer__link"
                    href="https://www.instagram.com/pretlakpretlakpretlak/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </p>
                <p>
                  <a
                    className="footer__link"
                    href="https://www.facebook.com/pretlakpretlakpretlak"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </p>
                <p>
                  <a
                    className="footer__link"
                    href="https://www.linkedin.com/company/pretlakpretlakpretlak/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </p>
                <p>
                  <a
                    className="footer__link"
                    href="https://www.tiktok.com/@pretlakpretlakpretlak"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TikTok
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="footer__contact">
            <div className="container">
              <div className="footer__contact-left">
                <span>{t('common.footer.text')} </span>
                <Emoji>
                  <a
                    href="mailto:pre@pretlak.sk"
                    className="link link--white margin-right-10"
                  >
                    pre@pretlak.sk
                  </a>
                  {/*<span>*/}
                  {/*  🇸🇰{' '}*/}
                  {/*  <a*/}
                  {/*    href="tel:+421947902989"*/}
                  {/*    className="link link--white margin-right-10"*/}
                  {/*  >*/}
                  {/*    +421 947 902 989*/}
                  {/*  </a>*/}
                  {/*</span>*/}
                  {/*<span>*/}
                  {/*  🇨🇿{' '}*/}
                  {/*  <a href="tel:+420775163449" className="link link--white">*/}
                  {/*    +420 775 163 449*/}
                  {/*  </a>*/}
                  {/*</span>*/}
                </Emoji>
              </div>

              <div>
                <Link href="/gdpr" className="footer__link">
                  GDPR
                </Link>
                <Link href="/terms-of-use" className="footer__link">
                  VOP
                </Link>
                <a
                  href="#"
                  onClick={showCookies}
                  className="footer__link footer__link-no-margin"
                >
                  Cookies
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </Translate>
  )
}

export default Footer
