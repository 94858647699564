module.exports = {
  title: '#NárodnáGalériaPráce',
  tag: {
    sng: '#NárodnáGalériaPráce',
    support: '#HľadámePodporu',
  },
  job: {
    banner: 'Podporte vznik tohto pracovného miesta na',
    sng: {
      heading: '#NárodnáGalériaPráce',
      text: 'Chcem sa zapojiť do iniciatívy a pomôcť profesionálom<br /> zo sveta kultúry, ktorí aktuálne hľadajú prácu.',
      textFreelancer: 'Som profesionál*ka z oblasti kultúry a aktuálne si hľadám prácu',
    },
    sngSupport: {
      heading: '#HľadámePodporu',
      text: 'Som kultúrna alebo nezisková inštitúcia, ktorá chce vytvoriť nové<br />  pracovné miesto, no zatiaľ nemá na jeho financovanie potrebné prostriedky.',
    },
  },
}
