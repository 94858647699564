module.exports = {
  keywords:
    'kreativní joby, freelance joby, design job, pracovní nabídky, práce pro designéra, práce pro vývojáře, práce pro marketéra',
  title: {
    admin: {
      freelancers: 'Admin: Freelanceři',
      social: 'Admin: Job - Zveřejnit na sociální sítě',
      photoUpload: 'Admin: Upload Loga / Fotky',
      contact: 'Admin: Kontaktovať',
      db: 'Admin: Users DB',
      company: {
        jobs: 'Admin: Company - Jobs',
      },
    },
    default: 'Joby z oblasti designu, IT, marketingu a Remote',
    home: 'Joby pro designéry, marketéry, IT a Remote',
    payment: 'Platební údaje',
    pricing: 'Ceník',
    emailAdded: 'Cool, byl jsi přidán do našeho newsletteru',
    emailAlmostAdded: 'Na 99% to už máme...',
    companies: 'Firmy',
    forgotPassword: 'Zapomenuté heslo do firemního profilu',
    forgotPasswordSuccess: 'Úspěšně zresetované heslo',
    freelancersDeleteSuccess: 'Smazání profilu úspěšné',
    freelancersDetail: 'Freelanceři - designéři, marketéři, developeři, kreativci',
    freelancersPasswordSuccess: 'Úspěšně zresetované heslo freelancera',
    freelancersForgotPassword: 'Zapomenuté heslo freelancera',
    freelancers: 'Freelanceři - designéři, marketéři, developeři, kreativci',
    freelancersLogin: 'Přihlášení do profilu freelancera',
    freelancersBonus: 'Bonusy',
    freelancersEdit: 'Úprava profilu',
    freelancersMyProfile: 'Můj profil',
    freelancersProfileSettings: 'Nastavení účtu',
    freelancersResetPasswordSuccess: 'Nové heslo do profilu freelancera je na světě',
    freelancersPro: 'Přejdi na PRO a získej víc!',
    freelancersFavourite: 'Oblíbený',
    freelancersResetPassword: 'Zadej své nové heslo do profilu freelancera',
    freelancersSignup: 'Registrace freelancera',
    gdpr: 'Ochrana osobních údajů',
    addJob: 'Přidat job',
    editJob: 'Úprava jobu',
    editJobDraft: 'Úprava rozpracované nabídky',
    republishJob: 'Duplikování jobu',
    jobEditNotAllowed: 'Nastal problém',
    jobEditSuccess: 'Úprava jobu úspěšná',
    jobEditError: 'Úprava jobu selhala',
    jobCvError: 'Žádost neodeslána',
    jobCvSuccess: 'Žádost odeslána',
    login: 'Přihlášení do firemního profilu',
    magazineDetail: 'Přetlak Magazín',
    magazine: 'Přetlak Magazín',
    unsubscribe: 'Nastavení e-mail notifikací',
    approvalUser: 'Souhlas dotyčné osoby se zpracováním osobních údajů',
    approvalUserDb: 'Souhlas dotyčné osoby se zpracováním osobních údajů',
    approvalMarketing: 'Souhlas dotyčné osoby se zpracováním osobních údajů',
    stats: 'Aktuální měsíční statistiky',
    signup: 'Registruj svou firmu',
    resetPassword: 'Zadej své nové heslo do firemního profilu',
    resetPasswordSuccess: 'Nové heslo je na světě',
    companySettings: 'Nastavení účtu',
    jobReport: 'Report jobu',
    companyInvoices: 'Vaše faktury',
    companyProfileHome: 'Pracovní nabídky',
    companyFreelancers: 'Freelanceri',
    companyProfileDb: 'Databáze životopisů',
    companyProfileEdit: 'Firemní údaje',
    companyProfileBuy: 'Výhodné balíčky',
    termsOfUse: 'Podmínky používání',
    about: 'Pár slov o nás',
    magazineCategory: 'Přetlak Magazín',
    manifest: '#NEŘEŠÍME',
    sng: '#NárodnáGalériaPráce',
  },
  description: {
    default:
      'Aktuální joby pro designéry, developery, marketéry a kreativce. I Remote. Najdi si svůj dream job a nového kolegu.',
    home: 'Jsme moderní job portál s přehledem aktuálních jobů pro designéry, marketéry, developery a kreativce. I Remote.',
    payment: 'Zkontrolujte své fakturační údaje a vyberte platební metodu',
    pricing: 'Viz aktuální ceník našich služeb',
    emailAdded:
      'Každý týden ti přijde do inboxu e-mail s aktuálními nabídkami. Cool, ne?',
    emailAlmostAdded:
      'Tak a teď zkontroluj svou e-mailovou schránku. Stačí kliknout na link a budeš ve hře!',
    companies: 'Podívejte se na firmy, které u nás inzerují',
    forgotPassword:
      'Zapomenuté heslo do firemního profilu? Nevadí, zadej váš registrační e-mail a my vám ho hezky zresetujeme. ',
    forgotPasswordSucces:
      'Nyní zkontroluj svůj e-mail a dokonči proces resetování hesla.',
    freelancersDeleteSuccess:
      'Je nám líto, že odcházíš, ale takový je život. Kdyby něco, víš, kde nás najdeš. ',
    freelancersDetail:
      'Vyber si z aktuálního seznamu freelancera toho svého a pusťte se do práce.',
    freelancersPasswordSuccess:
      'Nyní můžeš zkontrolovat svůj e-mail a dokončit proces resetování hesla.',
    freelancersForgotPassword:
      'Zapomenuté heslo? Nevadí, zadej svůj e-mail a my ti ho pěkně zresetujeme. ',
    freelancers:
      'Vyber si z aktuálního seznamu freelancera toho svého a pusťte se do práce.',
    freelancersLogin:
      'Přihlas se do svého profilu a vymakej ho na maximum. Klienti už čekají.',
    freelancersResetPasswordSuccess:
      'Fantazie. Užívej si své nové heslo a také náš web. Vidíme se.',
    freelancersResetPassword:
      'Už jen krok a je to. Zadej prosím své nové heslo. Takové, které si zapamatuješ. Nebo i ne.',
    freelancersSignup:
      'Vytvoř si svůj profil a buď součástí freelancerské špičky na Slovensku',
    freelancersPro:
      'S PRO účtem dostane tvůj profil zcela nový rozměr. Čerpej z benefitů, které tě dostanou do popředí. Získej více práce, ale také pláce. Jdeš do toho?',
    gdpr: 'Dbáme na ochranu osobních údajů? Samozřejmě. Tady najdeš vše ohledně GDPR a tvé osoby. Jinak to je docela nuda.',
    jobEditNotAllowed: 'K úpravě této nabídky potřebujete funkční heslo.',
    jobEditSuccess: 'Výborně! Job byl úspěšně upraven.',
    jobEditError: 'Ajaj, něco se pokazilo. Zkus to ještě jednou.',
    jobCvError: 'Ups, někde se stala chybička, zkus to znovu.',
    jobCvSuccess: 'Výborně! Žádost byla úspěšně odeslána.',
    login:
      'Přihlas se do firemního profilu, přidej job, viz uchazeče, zakup výhodný balík nebo topuj svou nabídku',
    magazine:
      'Kvalitní rozhovory s kreativci, aktuality z branže či inspirace na každý den',
    unsubscribe: 'Nastavte si jaké e-mail notifikace od nás chcete dostávat',
    approvalUser:
      'Najdeš zde účel zpracování tvých osobních údajů a další důležité informace.',
    approvalUserDb:
      'Najdeš zde účel zpracování tvých osobních údajů a další důležité informace.',
    approvalMarketing:
      'Najdeš zde účel zpracování tvých osobních údajů a další důležité informace.',
    stats:
      'Pokud tě zajímá, jak se Přetlaku dařilo uplynulý měsíc, čekni naše statistiky. Najdeš zde jen reálná data. Slibujeme.',
    signup:
      'Přidej svou firmu ještě dnes a čerpej z řady výhod – rychlý management jobů, seznam uchazečů, tajné nabídky',
    resetPassword: 'Tak a teď zadej své nové heslo a zapiš si ho za uši. Nebo i ne.',
    resetPasswordSuccess:
      'Wow. Úspěšně se ti podařilo vytvořit nové heslo. Těšíme se spolu s tebou.',
    termsOfUse:
      'Máme na našem webu nějaká pravidla a podmínky? Nějaké přece jen ano, a tady je najdeš pěkně pohromadě.',
    about: 'Kdo jsme, co děláme, na co jsme tady? Tady se to dozvíte...',
    magazineCategory:
      'Kvalitní rozhovory s kreativci, aktuality z branže či inspirace na každý den',
    manifest:
      'Věříme, že diverzita na pracovišti přináší mnoho benefitů a nikdo by se v práci neměl setkat s diskriminací. Zaměstnavatelé, kteří se rozhodnou náš manifest podpořit, tím veřejně deklarují, že budou pro své zaměstnance a zaměstnankyně aktivně vytvářet a udržovat inkluzivní pracovní prostředí. Uchazeči o pracovní pozici se tak mohou spolehnout na to, že ve své budoucí práci budou moci být bez obav sami sebou, bez ohledu na jejich sexuální orientaci, pohlaví, věk, etnicitu, náboženství nebo zdravotní znevýhodnění.',
    sng: 'Kultúra nie je len o inštitúciách. Kultúra je o ľuďoch.',
  },
}
