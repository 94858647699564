const common = require('./common')
const navigation = require('./navigation')
const filter = require('./filter')
const magazine = require('./magazine')
const testimonials = require('./testimonials')
const freelancers = require('./freelancers')
const label = require('./label')
const pricing = require('./pricing')
const signup = require('./signup')
const stats = require('./stats')
const jobs = require('./jobs')
const notifications = require('./notifications')
const meta = require('./meta')
const invoices = require('./invoices')
const countries = require('./countries')
const manifest = require('./manifest')
const sng = require('./sng')

module.exports = {
  common,
  filter,
  navigation,
  magazine,
  testimonials,
  freelancers,
  meta,
  invoices,
  countries,
  manifest,
  sng,
  newsletter: {
    title: 'Nové ponuky jobov',
    introduction:
      'Každý týždeň naša Tlačenka priamo do tvojho mailboxu. Vyber si kategóriu a zadaj tvoj e-mail.',
    confirmDesc: `Ďakujeme ti za záujem. Teraz ešte musíme potvrdiť tvoju e-mailovú adresu, nech
      je všetko na parádu. Ak chceš dokončiť tento cool proces, klikni prosím na odkaz
      v e-mailovej správe, ktorú sme ti práve poslali.`,
    successDesc: `Cool, bol si pridaný do nášho newslettra. Každý týždeň môžeš čakať fresh ponuku jobov!`,
    confirmTitle: 'Na 99% to už máme...',
  },
  label,
  pricing,
  signup,
  stats,
  jobs,
  notifications,
  aboutUs: {
    stat1: 'Založenie portálu',
    stat2: 'Hľadačov práce',
    stat3: 'Odoslaných CV-čiek',
    stat4: 'Pridaných ponúk',
    p1: 'Sme Pretlak, moderný job portál s novými pracovnými ponukami z marketingu, dizajnu, kreatívy, IT a súvisiacich odvetví.',
    p2: 'Na jednej strane sú skvelé príležitosti od top firiem a na strane druhej relevantní ľudia z brandže, ktorí tieto príležitosti vyhľadávajú. To všetko vám chceme naservírovať, čo najjednoduchšie a bez zbytočných klikov navyše, pretože v jednoduchosti je práca. Aj krása.',
    p3: 'Okrem toho podporujeme kreatívnu komunitu (sami sme jej súčasťou), freelancerov, prácu na diaľku a kvalitu nad kvantitou.',
    p4: 'Tešíme sa, že aj vďaka vám máme pretlak práce!',
  },
  og: {
    defaultTitle:
      'Joby pre developerov, dizajnérov, marketérov, IT a Remote | Pretlak.com',
  },
  email: {
    welcome: {
      subject: '👋 Dobrá práca!',
    },
    noJobs: {
      subject: '👋 Potrebujete poradiť?',
    },
    sendPublished: {
      subject: '✅ Pracovná ponuka bola schválená',
    },
    sendNotPublished: {
      subject: '⛔️ Pracovná ponuka nebola schválená',
    },
    jobApplyUser: {
      subject: '✉️ Tvoja žiadosť bola odoslaná',
    },
    jobApplyCompany: {
      subject1: 'Cool, máte nového záujemcu!',
      subject2: 'Máte nového záujemcu!',
    },
    jobEndClosed: {
      subject: `🤝 Ponuka bola uzatvorená`,
    },
    jobEndExpired: {
      subject1: '🔝 Vašu ponuku môžte topovať',
      subject2: '💎 Prémiové zobrazenie skončilo',
      subject3: '👑 Topované zobrazenie skončilo',
    },
    jobCloseReminder: {
      subject: '🔔 Pracovná ponuka je stále aktívna',
    },
    jobCloseLastReminder: {
      subject: '⛔️ Ponuka bude zmazaná...',
    },
    jobPaymentReminder: {
      subject: '🧾 Faktúra je neuhradená',
    },
    jobLowPerf: {
      subject: '🔔 Pracovná ponuka je stále aktívna',
    },
    packageExpired: {
      subject: `📦 Platnosť výhodného balíka skončila`,
    },
    forgotPassword: {
      subject: '🔑 Zabudli ste svoje heslo?',
    },
    sendFreelancerPublished: {
      subject: '👋 Vitaj medzi nami!',
    },
    sendFreelancerAdminContact: {
      subject: '👋 Tvoj profil freelancera potrebuje úpravu',
    },
    sendJobDraftAdminContact: {
      subject: '👋 Tvoj job čaká na dokončenie',
    },
    candidateRejection: {
      subject: '😞 Hm, tento raz to nevyšlo...',
    },
    candidateApproval: {
      subject: '🎉 Dobrá správa!',
    },
    failedPayment: {
      subject: '🚨 Platba nebola úspešná...',
    },
    invoicePaid: {
      subject: '🚨 Dôležité upozornenie k faktúre',
    },
    sendNoActiveCompany: {
      subject: '👋 Dávno sme sa nevideli...',
    },
    promocodeWillExpire: {
      subject: `🚨 Platnosť promokódu čoskoro skončí`,
    },
    promocodeStillOnline: {
      subject: `🚨 Máte nevyužité ponuky...`,
    },
    jobDraft: {
      subject: `✏️ Rozpracovná ponuka…`,
    },
    sendFreelancerContact: {
      subject: '💡 Aha, nový návrh na spoluprácu',
    },
    topFreelancer: {
      subject: 'Chceš byť navrchu zoznamu? Topuj svoj profil 👀',
    },
    freelancerAvailability: {
      subject: '👁️ Dlho sme sa nevideli: Máš voľné kapacity?',
    },
  },
  companyModal: {
    heading: 'Hľadajte kolegov výhodnejšie',
    item: 'Zakúpte si balík 5 alebo 10 jobov a rozbaľte hiring naplno. Je to rýchlejšie a výhodnejšie. Vybrať si môžete balíky ŠTANDARD, PRÉMIUM alebo EXKLUZÍV s úsporou až 30 %.',
    cta: 'Pozrieť ponuku',
  },
  loginBanner: {
    pill: 'WOW',
    heading: 'HĽADAJTE <br/>KOLEGOV <br/>VÝHODNEJŠIE',
    text: 'Kúpte 5 alebo 10 jobov vo výhodnom balíku a ušetrite až 30 %.',
    badge: 'ZĽAVA 30 %',
    cta: 'Šup, šup',
  },
  profileModal: {
    heading: 'Novinka!',
    subheading: 'Prejdi na PRO a získaj viac! Len za {{price}}&nbsp;€/mesiac.',
    cta: 'Naozaj? Zobraz mi viac info!',
    items: [
      '🔥 Fotka v profile',
      '🔥 Video v profile',
      '🔥 PPC kampaň',
      '🔥 TOP zobrazenie na webe',
      '🔥 Promo na sociálnych sieťach',
      '🔥 Podfarbenie profilu',
    ],
  },
  hiringUpsell: {
    titleRaw: 'Nenašli ste vhodný balík?',
    title: 'Nenašli ste <br/>vhodný balík?',
    text: 'V ponuke máme aj nekonečný balík alebo balík na mieru',
    textOneLine: 'V ponuke máme aj nekonečný balík alebo balík na mieru',
    cta: 'Napíšte mi!',
    writeMe: 'Napíšte mi',
    orCallMe: 'alebo zavolajte',
    smallBannerText: 'Inzerát a celý hiring vybavíme za vás! Zavolajte',
  },
}
