module.exports = {
  keywords:
    'kreatívne joby, freelance joby, dizajn job, pracovné ponuky, práca pre dizajnéra, práca pre vývojára, práca pre marketéra',
  title: {
    admin: {
      freelancers: 'Admin: Freelanceri',
      social: 'Admin: Job - Uverejniť na sociálne siete',
      photoUpload: 'Admin: Upload Loga / Fotky',
      contact: 'Admin: Kontaktovať',
      db: 'Admin: Users DB',
      company: {
        jobs: 'Admin: Company - Jobs',
      },
    },
    default: 'Joby z oblasti dizajnu, IT, marketingu a Remote',
    home: 'Joby pre dizajnérov, marketérov, IT a Remote',
    payment: 'Platobné údaje',
    pricing: 'Cenník',
    emailAdded: 'Cool, bol si pridaný do nášho newslettra',
    emailAlmostAdded: 'Na 99% to už máme...',
    companies: 'Firmy',
    forgotPassword: 'Zabudnuté heslo do firemného profilu',
    forgotPasswordSuccess: 'Úspešne zresetované heslo',
    freelancersDeleteSuccess: 'Zmazanie profilu úspešné',
    freelancersDetail: 'Freelanceri - dizajnéri, marketéri, developeri, kreatívci',
    freelancersPasswordSuccess: 'Úspešne zresetované heslo freelancera',
    freelancersForgotPassword: 'Zabudnuté heslo freelancera',
    freelancers: 'Freelanceri - dizajnéri, marketéri, developeri, kreatívci',
    freelancersLogin: 'Prihlásenie do profilu freelancera',
    freelancersBonus: 'Bonusy',
    freelancersEdit: 'Úprava profilu',
    freelancersMyProfile: 'Môj profil',
    freelancersProfileSettings: 'Nastavenie účtu',
    freelancersResetPasswordSuccess: 'Nové heslo do profilu freelancera je na svete',
    freelancersPro: 'Prejdi na PRO a získaj viac!',
    freelancersFavourite: 'Obľúbený',
    freelancersResetPassword: 'Zadaj svoje nové heslo do profilu freelancera',
    freelancersSignup: 'Registrácia freelancera',
    gdpr: 'Ochrana osobných údajov',
    addJob: 'Pridať job',
    editJob: 'Úprava jobu',
    editJobDraft: 'Úprava rozpracovanej ponuky',
    republishJob: 'Duplikovanie jobu',
    jobEditNotAllowed: 'Nastal problém',
    jobEditSuccess: 'Úprava jobu úspešná',
    jobEditError: 'Úprava jobu zlyhala',
    jobCvError: 'Žiadosť neodoslaná',
    jobCvSuccess: 'Žiadosť odoslaná',
    login: 'Prihlásenie do firemného profilu',
    magazineDetail: 'Pretlak Magazín',
    magazine: 'Pretlak Magazín',
    unsubscribe: 'Nastavenie e-mail notifikácií',
    approvalUser: 'Súhlas dotknutej osoby so spracovaním osobných údajov',
    approvalUserDb: 'Súhlas dotknutej osoby so spracovaním osobných údajov',
    approvalMarketing: 'Súhlas dotknutej osoby so spracovaním osobných údajov',
    stats: 'Aktuálne mesačné štatistiky',
    signup: 'Registruj svoju firmu',
    resetPassword: 'Zadaj svoje nové heslo do firemného profilu',
    resetPasswordSuccess: 'Nové heslo je na svete',
    companySettings: 'Nastavenie účtu',
    jobReport: 'Report jobu',
    companyInvoices: 'Vaše faktúry',
    companyProfileHome: 'Pracovné ponuky',
    companyFreelancers: 'Freelanceri',
    companyProfileDb: 'Databáza životopisov',
    companyProfileEdit: 'Firemné údaje',
    companyProfileBuy: 'Výhodné balíky',
    termsOfUse: 'Podmienky používania',
    about: 'Pár slov o nás',
    magazineCategory: 'Pretlak Magazín',
    manifest: '#NERIEŠIME',
    sng: '#NárodnáGalériaPráce',
  },
  description: {
    default:
      'Aktuálne joby pre dizajnérov, developerov, marketérov a kreatívcov. Aj Remote. Nájdi si svoj dream job a nového kolegu.',
    home: 'Sme moderný job portál s prehľadom aktuálnych jobov pre dizajnérov, marketérov, developerov a kreatívcov. Aj Remote.',
    payment: 'Skontrolujte svoje fakturačné údaje a vyberte platobnú metódu',
    pricing: 'Pozri si aktuálny cenník našich služieb',
    emailAdded:
      'Každý týždeň ti príde do inboxu e-mail s aktuálnymi ponukami. Cool, nie?',
    emailAlmostAdded:
      'Tak a teraz skontroluj svoju e-mailovú schránku. Stačí kliknúť na link a budeš v hre!',
    companies: 'Pozri si firmy ktoré u nás inzerujú',
    forgotPassword:
      'Zabudnuté heslo do firemného profilu? Nevadí, zadaj váš registračný e-mail a my vám ho pekne zresetujeme. ',
    forgotPasswordSucces:
      'Teraz skontroluj svoj e-mail a dokonči proces resetovania hesla.',
    freelancersDeleteSuccess:
      'Je nám ľuto, že odchádzaš, ale taký je život. Keby niečo, vieš, kde nás nájdeš. ',
    freelancersDetail:
      'Vyber si z aktuálneho zoznamu freelancera toho svojho a pustite sa do práce.',
    freelancersPasswordSuccess:
      'Teraz môžeš skontrolovať svoj e-mail a dokončiť proces resetovania hesla.',
    freelancersForgotPassword:
      'Zabudnuté heslo? Nevadí, zadaj svoj e-mail a my ti ho pekne zresetujeme. ',
    freelancers:
      'Vyber si z aktuálneho zoznamu freelancera toho svojho a pustite sa do práce.',
    freelancersLogin:
      'Prihlás sa do svojho profilu a vymakaj ho na maximum. Klienti už čakajú.',
    freelancersResetPasswordSuccess:
      'Fantázia. Užívaj si svoje nové heslo a tiež náš web. Vidíme sa.',
    freelancersPro:
      'S PRO účtom dostane tvoj profil úplne nový rozmer. Čerpaj z benefitov, ktoré ťa dostanú do popredia. Získaj viac práce, ale aj pláce. Ideš do toho?',
    freelancersResetPassword:
      'Už len krok a je to. Zadaj prosím svoje nové heslo. Také, ktoré si zapamätáš. Alebo aj nie.',
    freelancersSignup:
      'Vytvor si svoj profil a buď súčasťou freelancerskej špičky na Slovensku',
    gdpr: 'Dbáme na ochranu osobných údajov? Samozrejme. Tu nájdeš všetko ohľadne GDPR a tvojej osoby. Inak to je celkom nuda.',
    jobEditNotAllowed: 'Na úpravu tejto ponuky potrebujete funkčné heslo.',
    jobEditSuccess: 'Výborne! Job bol úspešne upravený.',
    jobEditError: 'Ajaj, niečo sa pokazilo. Skús to ešte raz.',
    jobCvError: 'Ups, niekde sa stala chybička, skús to znovu.',
    jobCvSuccess: 'Výborne! Žiadosť bola úspešne odoslaná.',
    login:
      'Prihlás sa do firemného profilu, pridaj job, pozri si uchádzačov, zakúp výhodný balík alebo topuj svoju ponuku',
    magazine:
      'Kvalitné rozhovory s kreatívcami, aktuality z brandže či inšpirácie na každý deň',
    unsubscribe: 'Nastavte si aké e-mail notifikácie od nás chcete dostávať',
    approvalUser:
      'Nájdeš tu účel spracovania tvojich osobných údajov a ďalšie dôležité informácie.',
    approvalUserDb:
      'Nájdeš tu účel spracovania tvojich osobných údajov a ďalšie dôležité informácie.',
    approvalMarketing:
      'Nájdeš tu účel spracovania tvojich osobných údajov a ďalšie dôležité informácie.',
    stats:
      'Ak ťa zaujíma, ako sa Pretlaku darilo uplynulý mesiac, čekni naše štatistiky. Nájdeš tu iba reálne dáta. Sľubujeme.',
    signup:
      'Pridaj svoju firmu ešte dnes a čerpaj z množstva výhod – rýchly manažment jobov, zoznam uchádzačov, tajné ponuky',
    resetPassword:
      'Tak a teraz zadaj svoje nové heslo a zapíš si ho za uši. Alebo aj nie.',
    resetPasswordSuccess:
      'Wow. Úspešne sa ti podarilo vytvoriť nové heslo. Tešíme sa spolu s tebou.',
    termsOfUse:
      'Máme na našom webe nejaké pravidlá a podmienky? Nejaké predsalen áno, a tu ich nájdeš pekne pokope.',
    about: 'Kto sme, čo robíme, na čo sme tu? Tu sa to dozviete...',
    magazineCategory:
      'Kvalitné rozhovory s kreatívcami, aktuality z brandže či inšpirácie na každý deň',
    manifest:
      'V Pretlaku veríme, že diverzita na pracovisku prináša mnoho benefitov a nikto by sa v práci nemal stretnúť s diskrimináciou. Zamestnávatelia, ktorí sa rozhodnú náš manifest podporiť, tým verejne deklarujú, že budú pre svojich zamestnancov a zamestnankyne aktívne vytvárať a udržiavať inkluzívne pracovné prostredie. Uchádzači o pracovnú pozíciu sa tak môžu spoľahnúť na to, že vo svojej budúcej práci budú môcť byť bez obáv sami sebou, bez ohľadu na ich sexuálnu orientáciu, pohlavie, vek, etnicitu, náboženstvo alebo zdravotné znevýhodnenie.',
    sng: 'Kultúra nie je len o inštitúciách. Kultúra je o ľuďoch.',
  },
}
